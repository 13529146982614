import { DetailedHTMLProps } from 'react';

type HtmlSvgProps = DetailedHTMLProps<
  React.SVGAttributes<SVGSVGElement>,
  SVGSVGElement
>;

export const IconEmojiPackage = (props: HtmlSvgProps) => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        d="M67 115.5V29.5L3 21V104L67 115.5Z"
        fill="url(#paint0_linear_745_356)"
      />
      <path
        d="M125 21L67 29.5V115.5L125 101V21Z"
        fill="url(#paint1_linear_745_356)"
      />
      <path
        d="M3 21L67 29.5L125 21L64 15.5L3 21Z"
        fill="url(#paint2_linear_745_356)"
      />
      <mask
        id="mask0_745_356"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="15"
        width="122"
        height="101"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 21L64 15.5L125 21V101L67 115.5L3 104V21Z"
          fill="url(#paint3_linear_745_356)"
        />
      </mask>
      <g mask="url(#mask0_745_356)">
        <g opacity="0.25" filter="url(#filter0_f_745_356)">
          <path
            d="M67 29.5L3 21V104L67 115.5V29.5Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
      </g>
      <mask
        id="mask1_745_356"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="67"
        y="21"
        width="58"
        height="95"
      >
        <path
          d="M125 21L67 29.5V115.5L125 101V21Z"
          fill="url(#paint4_linear_745_356)"
        />
      </mask>
      <g mask="url(#mask1_745_356)">
        <mask
          id="mask2_745_356"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="90"
          y="22"
          width="25"
          height="22"
        >
          <path d="M90 44V26L114.5 22.5V38.5L90 44Z" fill="#9B8977" />
        </mask>
        <g mask="url(#mask2_745_356)">
          <path d="M90 44V26L114.5 22.5V38.5L90 44Z" fill="#B7A18A" />
          <path d="M104 24V41" stroke="#C1AC97" />
        </g>
      </g>
      <mask
        id="mask3_745_356"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="15"
        width="122"
        height="15"
      >
        <path
          d="M3 21L67 29.5L125 21L64 15.5L3 21Z"
          fill="url(#paint5_linear_745_356)"
        />
      </mask>
      <g mask="url(#mask3_745_356)">
        <path d="M94 26.5L27 18.5L48 16.5L120 23L94 26.5Z" fill="#DDC7AF" />
        <path d="M32.5 17L107.5 24.5" stroke="#C1AC97" />
      </g>
      <path d="M64 31.5L38 28V49.5L64 53.5V31.5Z" fill="white" />
      <path d="M56 32.5L39.5 30V32L56 34.5V32.5Z" fill="#464646" />
      <path d="M56 36.5L39.5 34V36L56 38.5V36.5Z" fill="#464646" />
      <path d="M52 40L39.5 38V40L52 42V40Z" fill="#464646" />
      <path d="M48 43.5L39.5 42V48L48 49.5V43.5Z" fill="#464646" />
      <path d="M62 46L50 44V45.5L62 47.5V46Z" fill="#464646" />
      <path d="M59.5 48.5L50 47V48.5L59.5 50V48.5Z" fill="#464646" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.696 83.546L119.595 79.773L122.494 82.0001L116.696 83.546ZM110.899 82.0921L108 85.8651L113.797 84.3191L110.899 82.0921ZM109.449 86.9785L112.348 86.2055V97.4555L109.449 98.2285V86.9785ZM118.146 84.6597L121.044 83.8867V95.1367L118.146 95.9097V84.6597ZM122.494 96.2501L108 100.115V101.615L122.494 97.7501V96.2501Z"
        fill="#4F3721"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.6961 96.319C98.4988 95.0382 102.392 89.9706 102.392 85L85 89.6379C85 94.6085 88.8934 97.5997 93.6961 96.319ZM87.1741 90.5583L87.8987 88.865L90.0728 88.2853L89.3481 89.9785L90.7975 90.342L87.8987 94.115L88.6234 91.6718L87.1741 90.5583ZM92.9714 98.0123L94.4207 97.6258V104.376L92.9714 104.762V98.0123ZM89.3481 105.729L98.0442 103.41V104.91L89.3481 107.229V105.729Z"
        fill="#4F3721"
      />
      <defs>
        <filter
          id="filter0_f_745_356"
          x="0"
          y="17.8584"
          width="70"
          height="100.837"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1"
            result="effect1_foregroundBlur_745_356"
          />
        </filter>
        <linearGradient
          id="paint0_linear_745_356"
          x1="31.1358"
          y1="140.636"
          x2="38.1541"
          y2="21.1853"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#704B22" />
          <stop offset="0.708333" stopColor="#B78B5C" />
          <stop offset="1" stopColor="#D8A670" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_745_356"
          x1="135.667"
          y1="74.1693"
          x2="50.5124"
          y2="37.984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#704B22" />
          <stop offset="0.708333" stopColor="#B78B5C" />
          <stop offset="1" stopColor="#D8A670" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_745_356"
          x1="89.4669"
          y1="-32.3046"
          x2="91.6419"
          y2="37.518"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#704B22" />
          <stop offset="0.708333" stopColor="#B78B5C" />
          <stop offset="1" stopColor="#D8A670" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_745_356"
          x1="89.4669"
          y1="-32.3046"
          x2="91.6419"
          y2="37.518"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#704B22" />
          <stop offset="0.708333" stopColor="#B78B5C" />
          <stop offset="1" stopColor="#D8A670" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_745_356"
          x1="135.667"
          y1="74.1693"
          x2="50.5124"
          y2="37.984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#704B22" />
          <stop offset="0.708333" stopColor="#B78B5C" />
          <stop offset="1" stopColor="#D8A670" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_745_356"
          x1="89.4669"
          y1="-32.3046"
          x2="91.6419"
          y2="37.518"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#704B22" />
          <stop offset="0.708333" stopColor="#B78B5C" />
          <stop offset="1" stopColor="#D8A670" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconEmojiPackage;
