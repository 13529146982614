import React, { lazy } from 'react';
import BodilyInjuryComponent from '~/src/components/ClaimWorkflow/BodilyInjury';
import BuilderComponent from '~/src/components/ClaimWorkflow/Builder';
import ChecklistComponent from '~/src/components/ClaimWorkflow/Checklist';
import ClinicalTableSearchComponent from '~/src/components/ClaimWorkflow/ClinicalTableSearch';
import CoverageSummaryComponent from '~/src/components/ClaimWorkflow/CoverageSummary';
import DateTimeComponent from '~/src/components/ClaimWorkflow/DateTime';
import { DateTimeV2 as DateTimeV2Component } from '~/src/components/ClaimWorkflow/DateTimeV2/DateTimeV2';
import DocumentComponent from '~/src/components/ClaimWorkflow/Document';
import Hidden from '~/src/components/ClaimWorkflow/Hidden';
import ListComponent from '~/src/components/ClaimWorkflow/List';
import LocationComponent from '~/src/components/ClaimWorkflow/Location';
import LocationDirectionComponent from '~/src/components/ClaimWorkflow/LocationDirection';
import MedicalAppointmentsComponent from '~/src/components/ClaimWorkflow/MedicalAppointments';
import MultipartyFollowupSummaryComponent from '~/src/components/ClaimWorkflow/MultipartyFollowupSummary';
import PartyEntry from '~/src/components/ClaimWorkflow/PartyEntry';
import SelectComponent from '~/src/components/ClaimWorkflow/Select';
import SelectMultiComponent from '~/src/components/ClaimWorkflow/SelectMulti';
import SelectOrCreateComponent from '~/src/components/ClaimWorkflow/SelectOrCreate';
import SignatureComponent from '~/src/components/ClaimWorkflow/Signature';
import StringComponent from '~/src/components/ClaimWorkflow/String';
import TimeslotSelectionComponent from '~/src/components/ClaimWorkflow/TimeslotSelection';
import UploadComponent from '~/src/components/ClaimWorkflow/Upload';
import VehicleSeatmapComponent from '~/src/components/ClaimWorkflow/VehicleSeatmap';
import WitnessEntry from '~/src/components/ClaimWorkflow/WitnessEntry';
import YourInvolvementConfirmationComponent from '~/src/components/ClaimWorkflow/YourInvolvement/YourInvolvementConfirmation';
import YourInvolvementModificationComponent from '~/src/components/ClaimWorkflow/YourInvolvement/YourInvolvementModification';

import { StepComponentRegistry } from '@assured/step-renderer/types';

import Graphic from '../inline/Graphic';
import Policy from '../inline/Policy';
import { IntersectionWizard } from '../IntersectionWizard';
import LegalDisclaimerComponent from '../LegalDisclaimer';
import ReconfirmBannerComponent from '../ReconfirmBanner';
import TelephoneNumberCTAComponent from '../TelephoneNumberCTA';
import Confetti from '../widgets/Confetti';
import Map from '../widgets/Map';
import SeatmapWidget from '../widgets/Seatmap';
import Weather from '../widgets/Weather';
import RedirectComponent from '../RedirectComponent';

const GuidedPhotoCaptureCTAs = lazy(
  () =>
    import(
      '~/src/components/ClaimWorkflow/GuidedPhotoCapture/GuidedPhotoCaptureCTAs'
    ),
);

const VehicleDamagePickerComponent = lazy(
  () => import('~/src/components/ClaimWorkflow/VehicleDamagePicker'),
);
const UserSatisfactionRating = React.lazy(
  () => import('~/src/components/ClaimWorkflow/UserSatisfaction'),
);
const RepairCoordinationComponent = React.lazy(
  () => import('~/src/components/ClaimWorkflow/RepairCoordination'),
);
const RentalCoordinationComponent = React.lazy(
  () => import('~/src/components/ClaimWorkflow/RentalCoordination'),
);
const RepairCoordinationConfirmationComponent = React.lazy(
  () =>
    import(
      '~/src/components/ClaimWorkflow/RepairCoordination/RepairCoordinationConfirmation'
    ),
);
const CollisionReconstructionComponent = React.lazy(
  () => import('~/src/components/ClaimWorkflow/CollisionReconstruction'),
);
const OutOfBandWorkflowContinuation = React.lazy(
  () =>
    import(
      '~/src/components/ClaimWorkflow/OutOfBandWorkflows/OutOfBandWorkflowContinuation'
    ),
);

// TODO: in the future this should likely not be Partial<>
export const selfServiceRegistry: Partial<StepComponentRegistry> = {
  bodily_injury: BodilyInjuryComponent,
  boolean: () => <React.Fragment />,
  builder: BuilderComponent,
  call_telephone_number_cta: TelephoneNumberCTAComponent,
  checklist: ChecklistComponent,
  claim_conclusion: () => <React.Fragment />,
  claim_routing: () => <React.Fragment />,
  clinical_table_search: ClinicalTableSearchComponent,
  collision_reconstruction: CollisionReconstructionComponent,
  coverage_summary: CoverageSummaryComponent,
  datetime: DateTimeComponent,
  datetimeV2: DateTimeV2Component,
  document: DocumentComponent,
  full_page_interstitial: () => <React.Fragment />,
  grouped_searchable_select: () => <React.Fragment />,
  guided_photo_capture_ctas: GuidedPhotoCaptureCTAs,
  hidden: Hidden,
  intersection_wizard: IntersectionWizard,
  interstitial: () => <React.Fragment />,
  integration_feedback: () => <React.Fragment />, // Not used in self-service
  legal_disclaimer: LegalDisclaimerComponent,
  license_plate_selector: () => <React.Fragment />,
  list: ListComponent,
  location_direction: LocationDirectionComponent,
  location: LocationComponent,
  medical_appointments: MedicalAppointmentsComponent,
  multiparty_followup_summary: MultipartyFollowupSummaryComponent,
  out_of_band_workflow_continuation: OutOfBandWorkflowContinuation,
  party_adder: () => <React.Fragment />,
  party_entry: PartyEntry,
  policy_search: () => <React.Fragment />,
  reconfirm_banner: ReconfirmBannerComponent,
  rental_coordination: RentalCoordinationComponent,
  repair_coordination: RepairCoordinationComponent,
  repair_coordination_confirmation: RepairCoordinationConfirmationComponent,
  scripting_instructions: () => <React.Fragment />,
  redirect: RedirectComponent,
  select_multi: SelectMultiComponent,
  select_or_create: SelectOrCreateComponent,
  select: SelectComponent,
  signature: SignatureComponent,
  toggleable_group: () => <React.Fragment />,
  vehicle_speed: () => <React.Fragment />,
  string: StringComponent,
  underwriting_memo_address: () => <React.Fragment />,
  underwriting_memo_phone: () => <React.Fragment />,
  upload: UploadComponent,
  user_satisfaction: UserSatisfactionRating,
  vehicle_damage_picker: VehicleDamagePickerComponent,
  vehicle_damage_region_picker: () => <React.Fragment />,
  vehicle_occupant_entry_wizard: () => <React.Fragment />,
  vehicle_occupant_entry_wizard_v2: () => <React.Fragment />,
  vehicle_seatmap: VehicleSeatmapComponent,
  vehicle_selection: () => <React.Fragment />,
  witness_entry: WitnessEntry,
  wait: () => <React.Fragment />,
  your_involvement_confirmation: YourInvolvementConfirmationComponent,
  your_involvement_modification: YourInvolvementModificationComponent,
  timeslot_selection: TimeslotSelectionComponent,
  widgets: {
    weather: Weather,
    vehicle_seatmap: SeatmapWidget,
    confetti: Confetti,
    map: Map,
  },
  inline: {
    policyInfo: Policy,
    graphic: Graphic,
  },
};
