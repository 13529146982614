import React from 'react';
import { Redirect } from 'react-router-dom';
import { useWorkflowId } from '~/src/hooks/useWorkflowId';

const getHashParams = (urlHash: UrlHash) => {
  // parse the hash as if it were a query string, because that's how they pass the data
  // they provide ['access_token', 'token_type' (Bearer), 'expires_in' (~3600), 'id_token']
  // noinspection UnnecessaryLocalVariableJS
  const hashParams = new URLSearchParams(`?${ urlHash?.slice(1) ?? ''}`);

  return hashParams;
};

export const OtpCallback = () => {
  const initialHash = window.__assuredInitialHash;

  const hashParams = getHashParams(initialHash);

  const { data: workflowId, loading } = useWorkflowId();

  if (loading) {
    return null;
  }

  const hashParamsUrlString = encodeURI(hashParams.toString());

  return <Redirect to={`/workflows/${workflowId}#${hashParamsUrlString}`} />;
};
