import { MedicalProviderOrganizationType } from '../../HealthProvider';
import DocumentType from '../Document';
import { LocationType } from '../LocationType';
import { MediaType } from '../Media';
import { PassengerType } from './Passenger';
import { VehicleType } from './Vehicle';

export enum ChildCarSeatType {
  BOOSTER_SEAT = 'BOOSTER_SEAT',
  CHILD_SEAT = 'CHILD_SEAT',
}

export enum VehicleOccupantSeat {
  DRIVER = 'DRIVER',
  PASSENGER = 'PASSENGER',
  SECOND_ROW_DRIVER_SIDE = 'SECOND_ROW_DRIVER_SIDE',
  SECOND_ROW_MIDDLE = 'SECOND_ROW_MIDDLE',
  SECOND_ROW_PASSENGER_SIDE = 'SECOND_ROW_PASSENGER_SIDE',
  THIRD_ROW_DRIVER_SIDE = 'THIRD_ROW_DRIVER_SIDE',
  THIRD_ROW_MIDDLE = 'THIRD_ROW_MIDDLE',
  THIRD_ROW_PASSENGER_SIDE = 'THIRD_ROW_PASSENGER_SIDE',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
}

// FIXME: This is a duplicate of an enum in Prisma. Trying to import it from
// prisma was breaking the build, so I copied it here.
enum TripWorkType {
  Uber = 'Uber',
  UberEats = 'UberEats',
  Lyft = 'Lyft',
  Other = 'Other',
}

export interface ChildCarSeat {
  id: string;

  involvedParty: InvolvedPartyType;
  involvedPartyId?: string;

  seat?: VehicleOccupantSeat | 'UNKNOWN';
  type?: ChildCarSeatType;

  wasOccupied?: boolean;
  wasDamaged?: boolean;
}

export enum RepairIntent {
  CarrierCoordinated = 'CARRIER_COORDINATED',
  Payout = 'PAYOUT',
  Undecided = 'UNDECIDED',
  CustomerCoordinated = 'CUSTOMER_COORDINATED',
}

export enum RentalIntent {
  ACCEPTED_COVERED = 'ACCEPTED_COVERED',
  ACCEPTED_NOT_COVERED = 'ACCEPTED_NOT_COVERED',
  DECLINED_COVERED = 'DECLINED_COVERED',
  DECLINED_NOT_COVERED = 'DECLINED_NOT_COVERED',
}

export enum CommercialAutoGaragekeeperVehicleDamageContexts {
  TOWING = 'TOWING',
  STORING = 'STORING',
  LOADING = 'LOADING',
  UNLOADING = 'UNLOADING',
}

export enum CommercialAutoVehicleType {
  TRACTOR = 'TRACTOR',
  DUMP_TRUCK_GVW_0_16K = 'DUMP_TRUCK_GVW_0_16K',
  FLATBED_TRUCK_GVW_0_16K = 'FLATBED_TRUCK_GVW_0_16K',
  TOW_TRUCK_SINGLE_AXLE_GVW_0_26K = 'TOW_TRUCK_SINGLE_AXLE_GVW_0_26K',
  TOW_TRUCK_SINGLE_AXLE_GVW_26K_PLUS = 'TOW_TRUCK_SINGLE_AXLE_GVW_26K_PLUS',
  PICKUP_TRUCK_0_HALF_TON_4X2 = 'PICKUP_TRUCK_0_HALF_TON_4X2',
  PICKUP_TRUCK_0_HALF_TON_4X4 = 'PICKUP_TRUCK_0_HALF_TON_4X4',
  PICKUP_TRUCK_HALF_TON_PLUS_4X4 = 'PICKUP_TRUCK_HALF_TON_PLUS_4X4',
  STAKE_TRUCK_GVW_0_16K = 'STAKE_TRUCK_GVW_0_16K',
  TANK_TRUCK_BELOW_1401_GALLONS = 'TANK_TRUCK_BELOW_1401_GALLONS',
  STREET_SWEEPER = 'STREET_SWEEPER',
  STRAIGHT_TRUCK_GVW_0_16K = 'STRAIGHT_TRUCK_GVW_0_16K',
  STRAIGHT_TRUCK_GVW_16K_26K = 'STRAIGHT_TRUCK_GVW_16K_26K',
  STRAIGHT_TRUCK_GVW_26K_PLUS = 'STRAIGHT_TRUCK_GVW_26K_PLUS',
  REFRIGERATED_TRUCK_GVW_0_16K = 'REFRIGERATED_TRUCK_GVW_0_16K',
  REFRIGERATED_TRUCK_GVW_16K_26K = 'REFRIGERATED_TRUCK_GVW_16K_26K',
  REFRIGERATED_TRUCK_GVW_26K_PLUS = 'REFRIGERATED_TRUCK_GVW_26K_PLUS',
  ICE_CREAM_TRUCK = 'ICE_CREAM_TRUCK',
  CEMENT_MIXER = 'CEMENT_MIXER',
  FLATBED_TRUCK_GVW_26K_PLUS = 'FLATBED_TRUCK_GVW_26K_PLUS',
  TOW_TRUCK_2_AXLE = 'TOW_TRUCK_2_AXLE',
  PICKUP_TRUCK_HALF_TON_PLUS_4X2 = 'PICKUP_TRUCK_HALF_TON_PLUS_4X2',
  CATERING_TRUCK = 'CATERING_TRUCK',
  STAKE_TRUCK_GVW_26K_PLUS = 'STAKE_TRUCK_GVW_26K_PLUS',
  PICKUP_WITH_FIFTH_WHEEL_HITCH = 'PICKUP_WITH_FIFTH_WHEEL_HITCH',
  DUMP_TRUCK_GVW_16K_33K = 'DUMP_TRUCK_GVW_16K_33K',
  FLATBED_TRUCK_GVW_16K_26K = 'FLATBED_TRUCK_GVW_16K_26K',
  FRONT_LOADER_GVW_0_45K = 'FRONT_LOADER_GVW_0_45K',
  GARBAGE_TRUCK_GVW_0_45K = 'GARBAGE_TRUCK_GVW_0_45K',
  ROLL_ON_VEHICLE_GVW_0_45K = 'ROLL_ON_VEHICLE_GVW_0_45K',
  STAKE_TRUCK_GVW_16K_26K = 'STAKE_TRUCK_GVW_16K_26K',
  TANK_TRUCK_1401_GALLONS_PLUS = 'TANK_TRUCK_1401_GALLONS_PLUS',
  DUMP_TRUCK_GVW_45K_PLUS = 'DUMP_TRUCK_GVW_45K_PLUS',
  DUMP_TRUCK_GVW_33K_45K = 'DUMP_TRUCK_GVW_33K_45K',
  FRONT_LOADER_GVW_45K_PLUS = 'FRONT_LOADER_GVW_45K_PLUS',
  GARBAGE_TRUCK_GVW_45K_PLUS = 'GARBAGE_TRUCK_GVW_45K_PLUS',
  ROLL_ON_VEHICLE_GVW_45K_PLUS = 'ROLL_ON_VEHICLE_GVW_45K_PLUS',
  BUCKET_TRUCK_GVW_0_16K = 'BUCKET_TRUCK_GVW_0_16K',
  BUCKET_TRUCK_GVW_16K_26K = 'BUCKET_TRUCK_GVW_16K_26K',
  BUCKET_TRUCK_GVW_26K_PLUS = 'BUCKET_TRUCK_GVW_26K_PLUS',
  CAR_CARRIER_ROLLBACK_GVW_0_16K = 'CAR_CARRIER_ROLLBACK_GVW_0_16K',
  PUMP_TRUCK_GVW_0_16K = 'PUMP_TRUCK_GVW_0_16K',
  PUMP_TRUCK_GVW_16K_26K = 'PUMP_TRUCK_GVW_16K_26K',
  PUMP_TRUCK_GVW_26K_PLUS = 'PUMP_TRUCK_GVW_26K_PLUS',
  CAR_CARRIER_ROLLBACK_GVW_16K_PLUS = 'CAR_CARRIER_ROLLBACK_GVW_16K_PLUS',
  BOOM_CRANE_GVW_0_16K = 'BOOM_CRANE_GVW_0_16K',
  BOOM_CRANE_GVW_16K_26K = 'BOOM_CRANE_GVW_16K_26K',
  BOOM_CRANE_GVW_26K_PLUS = 'BOOM_CRANE_GVW_26K_PLUS',
  AGRICULTURAL_HOPPER_TRUCK = 'AGRICULTURAL_HOPPER_TRUCK',
  CARGO_PASSENGER_VAN = 'CARGO_PASSENGER_VAN',
  WHEELCHAIR_VAN = 'WHEELCHAIR_VAN',
  WHEELCHAIR_BUS_9_15_PASSENGERS = 'WHEELCHAIR_BUS_9_15_PASSENGERS',
  WHEELCHAIR_BUS_16_60_PASSENGERS = 'WHEELCHAIR_BUS_16_60_PASSENGERS',
  WHEELCHAIR_BUS_61_PLUS_PASSENGERS = 'WHEELCHAIR_BUS_61_PLUS_PASSENGERS',
  MINIVAN = 'MINIVAN',
  STEP_VAN_GVW_0_10K = 'STEP_VAN_GVW_0_10K',
  DELIVERY_VAN_GVW_10K_PLUS = 'DELIVERY_VAN_GVW_10K_PLUS',
  BUS_9_15_PASSENGERS = 'BUS_9_15_PASSENGERS',
  BUS_16_60_PASSENGERS = 'BUS_16_60_PASSENGERS',
  BUS_61_PLUS_PASSENGERS = 'BUS_61_PLUS_PASSENGERS',
  DRY_FREIGHT_TRAILER = 'DRY_FREIGHT_TRAILER',
  TANK_TRAILER = 'TANK_TRAILER',
  FLATBED_TRAILER = 'FLATBED_TRAILER',
  POLE_TRAILER = 'POLE_TRAILER',
  LIVESTOCK_TRAILER = 'LIVESTOCK_TRAILER',
  DUMP_BODY_TRAILER = 'DUMP_BODY_TRAILER',
  TRANSFER_DUMP_BODY_TRAILER = 'TRANSFER_DUMP_BODY_TRAILER',
  REFRIGERATED_DRY_FREIGHT_TRAILER = 'REFRIGERATED_DRY_FREIGHT_TRAILER',
  HORSE_TRAILER_1_2_STALLS = 'HORSE_TRAILER_1_2_STALLS',
  LOWBOY_TRAILER = 'LOWBOY_TRAILER',
  UNIDENTIFIED_TRAILER = 'UNIDENTIFIED_TRAILER',
  BULK_COMMODITY_TRAILER = 'BULK_COMMODITY_TRAILER',
  TILT_TRAILER = 'TILT_TRAILER',
  BOTTOM_DUMP_TRAILER = 'BOTTOM_DUMP_TRAILER',
  BOTTOM_DUMP_GRAIN_TRAILER = 'BOTTOM_DUMP_GRAIN_TRAILER',
  UNENCLOSED_UTILITY_TRAILER_0_12_FT = 'UNENCLOSED_UTILITY_TRAILER_0_12_FT',
  ENCLOSED_UTILITY_TRAILER_0_12_FT = 'ENCLOSED_UTILITY_TRAILER_0_12_FT',
  WEDGE_TRAILER = 'WEDGE_TRAILER',
  AUTO_HAULING_TRAILER = 'AUTO_HAULING_TRAILER',
  LARGE_HORSE_TRAILER = 'LARGE_HORSE_TRAILER',
  UNENCLOSED_UTILITY_TRAILER_13_PLUS_FT = 'UNENCLOSED_UTILITY_TRAILER_13_PLUS_FT',
  ENCLOSED_UTILITY_TRAILER_13_PLUS_FT = 'ENCLOSED_UTILITY_TRAILER_13_PLUS_FT',
  CONCESSION_TRAILER = 'CONCESSION_TRAILER',
  TRAVEL_TRAILER = 'TRAVEL_TRAILER',
  LOGGING_TRAILER = 'LOGGING_TRAILER',
  GOOSENECK_TRAILER = 'GOOSENECK_TRAILER',
  SPORT_AUTO = 'SPORT_AUTO',
  LUXURY_AUTO = 'LUXURY_AUTO',
  PRIVATE_PASSENGER_AUTO = 'PRIVATE_PASSENGER_AUTO',
  MOTOR_HOME = 'MOTOR_HOME',
  UTILITY_VEHICLE = 'UTILITY_VEHICLE',
  LUXURY_SUV = 'LUXURY_SUV',
  LIMOUSINE_0_120_INCHES = 'LIMOUSINE_0_120_INCHES',
  HEARSE = 'HEARSE',
  LIMOUSINE_121_180_INCHES = 'LIMOUSINE_121_180_INCHES',
  LIMOUSINE_181_PLUS_INCHES = 'LIMOUSINE_181_PLUS_INCHES',
}

export const CommercialAutoVehicleTypeDisplay: Record<
  CommercialAutoVehicleType,
  string
> = {
  TRACTOR: 'Tractor',
  DUMP_TRUCK_GVW_0_16K: 'Dump Truck (GVW 0-16,000)',
  FLATBED_TRUCK_GVW_0_16K: 'Flatbed Truck (GVW 0-16,000)',
  TOW_TRUCK_SINGLE_AXLE_GVW_0_26K:
    'Tow Truck (Single Axle, GVW less than 26,000)',
  TOW_TRUCK_SINGLE_AXLE_GVW_26K_PLUS: 'Tow Truck (Single Axle, GVW 26,001+)',
  PICKUP_TRUCK_0_HALF_TON_4X2:
    'Pickup Truck (Less than or equal to 1/2 ton, 4x2)',
  PICKUP_TRUCK_0_HALF_TON_4X4:
    'Pickup Truck (Less than or equal to 1/2 ton, 4x4)',
  PICKUP_TRUCK_HALF_TON_PLUS_4X4: 'Pickup Truck (Greater than 1/2 ton, 4x4)',
  STAKE_TRUCK_GVW_0_16K: 'Stake Truck (GVW 0-16,000)',
  TANK_TRUCK_BELOW_1401_GALLONS:
    'Tank Truck (Less than or equal to 1,400 gallons)',
  STREET_SWEEPER: 'Street Sweeper',
  STRAIGHT_TRUCK_GVW_0_16K: 'Straight Truck (GVW 0-16,000)',
  STRAIGHT_TRUCK_GVW_16K_26K: 'Straight Truck (GVW 16,001-26,000)',
  STRAIGHT_TRUCK_GVW_26K_PLUS: 'Straight Truck (GVW 26,001+)',
  REFRIGERATED_TRUCK_GVW_0_16K: 'Refrigerated Truck (GVW 0-16,000)',
  REFRIGERATED_TRUCK_GVW_16K_26K: 'Refrigerated Truck (GVW 16,001-26,000)',
  REFRIGERATED_TRUCK_GVW_26K_PLUS: 'Refrigerated Truck (GVW 26,001+)',
  ICE_CREAM_TRUCK: 'Ice Cream Truck',
  CEMENT_MIXER: 'Cement Mixer',
  FLATBED_TRUCK_GVW_26K_PLUS: 'Flatbed Truck (GVW 26,001)',
  TOW_TRUCK_2_AXLE: 'Tow Truck (2 Axle)',
  PICKUP_TRUCK_HALF_TON_PLUS_4X2: 'Pickup Truck (Greater than 1/2 ton, 4x2)',
  CATERING_TRUCK: 'Catering Truck',
  STAKE_TRUCK_GVW_26K_PLUS: 'Stake Truck (GVW 26,001+)',
  PICKUP_WITH_FIFTH_WHEEL_HITCH: 'Pickup with Fifth Wheel Hitch',
  DUMP_TRUCK_GVW_16K_33K: 'Dump Truck (GVW 16,001-33,000)',
  FLATBED_TRUCK_GVW_16K_26K: 'Flatbed Truck (GVW 16,001-26,000)',
  FRONT_LOADER_GVW_0_45K: 'Front Loader (GVW 0-45,000)',
  GARBAGE_TRUCK_GVW_0_45K: 'Garbage Truck (GVW 0-45,000)',
  ROLL_ON_VEHICLE_GVW_0_45K: 'Roll-On Vehicle (GVW 0-45,000)',
  STAKE_TRUCK_GVW_16K_26K: 'Stake Truck (GVW 16,000-26,000)',
  TANK_TRUCK_1401_GALLONS_PLUS: 'Tank Truck (1401 Gallons+)',
  DUMP_TRUCK_GVW_45K_PLUS: 'Dump Truck (GVW 45,001+)',
  DUMP_TRUCK_GVW_33K_45K: 'Dump Truck (GVW 33,001-45,000)', // Note: PGR document says 33,000-45,000 but this would overlap with the previous range (16,000-33,000); since that doesn't happen in the other ranges, I'm assuming this is a typo
  FRONT_LOADER_GVW_45K_PLUS: 'Front Loader (GVW 45,001+)',
  GARBAGE_TRUCK_GVW_45K_PLUS: 'Garbage Truck (GVW 45,001+)',
  ROLL_ON_VEHICLE_GVW_45K_PLUS: 'Roll-On Vehicle (GVW 45,001+)',
  BUCKET_TRUCK_GVW_0_16K: 'Bucket Truck (GVW 0-16,000)',
  BUCKET_TRUCK_GVW_16K_26K: 'Bucket Truck (GVW 16,001-26,000)',
  BUCKET_TRUCK_GVW_26K_PLUS: 'Bucket Truck (GVW 26,000+)',
  CAR_CARRIER_ROLLBACK_GVW_0_16K: 'Car Carrier Rollback (GVW 0-16,000)',
  PUMP_TRUCK_GVW_0_16K: 'Pump Truck (GVW 0-16,000)',
  PUMP_TRUCK_GVW_16K_26K: 'Pump Truck (GVW 16,001-26,000)',
  PUMP_TRUCK_GVW_26K_PLUS: 'Pump Truck (GVW 26,001+)',
  CAR_CARRIER_ROLLBACK_GVW_16K_PLUS: 'Car Carrier Rollback (GVW 16,001+)',
  BOOM_CRANE_GVW_0_16K: 'Boom Crane (GVW 0-16,000)',
  BOOM_CRANE_GVW_16K_26K: 'Boom Crane (GVW 16,001-26,000)',
  BOOM_CRANE_GVW_26K_PLUS: 'Boom Crane (GVW 26,001+)',
  AGRICULTURAL_HOPPER_TRUCK: 'Agricultural Hopper Truck',
  CARGO_PASSENGER_VAN: 'Cargo Passenger Van',
  WHEELCHAIR_VAN: 'Wheelchair Van',
  WHEELCHAIR_BUS_9_15_PASSENGERS: 'Wheelchair Bus (9-15 Passengers)',
  WHEELCHAIR_BUS_16_60_PASSENGERS: 'Wheelchair Bus (16-60 Passengers)',
  WHEELCHAIR_BUS_61_PLUS_PASSENGERS: 'Wheelchair Bus (61+ Passengers)',
  MINIVAN: 'Minivan',
  STEP_VAN_GVW_0_10K: 'Step Van (GVW 0-10,000)',
  DELIVERY_VAN_GVW_10K_PLUS: 'Delivery Van (GVW 10,000+)',
  BUS_9_15_PASSENGERS: 'Bus (9-15 Passengers)',
  BUS_16_60_PASSENGERS: 'Bus (16-60 Passengers)',
  BUS_61_PLUS_PASSENGERS: 'Bus (61+ Passengers)',
  DRY_FREIGHT_TRAILER: 'Dry Freight Trailer',
  TANK_TRAILER: 'Tank Trailer',
  FLATBED_TRAILER: 'Flatbed Trailer',
  POLE_TRAILER: 'Pole Trailer',
  LIVESTOCK_TRAILER: 'Livestock Trailer',
  DUMP_BODY_TRAILER: 'Dump Body Trailer',
  TRANSFER_DUMP_BODY_TRAILER: 'Transfer Dump Body Trailer',
  REFRIGERATED_DRY_FREIGHT_TRAILER: 'Refrigerated Dry Freight Trailer',
  HORSE_TRAILER_1_2_STALLS: 'Horse Trailer (1-2 Stalls)',
  LOWBOY_TRAILER: 'Lowboy Trailer',
  UNIDENTIFIED_TRAILER: 'Unidentified Trailer',
  BULK_COMMODITY_TRAILER: 'Bulk Commodity Trailer',
  TILT_TRAILER: 'Tilt Trailer',
  BOTTOM_DUMP_TRAILER: 'Bottom Dump Trailer',
  BOTTOM_DUMP_GRAIN_TRAILER: 'Bottom Dump Grain Trailer',
  UNENCLOSED_UTILITY_TRAILER_0_12_FT: 'Unenclosed Utility Trailer (0-12 ft)',
  ENCLOSED_UTILITY_TRAILER_0_12_FT: 'Enclosed Utility Trailer (0-12 ft)',
  WEDGE_TRAILER: 'Wedge Trailer',
  AUTO_HAULING_TRAILER: 'Auto Hauling Trailer',
  LARGE_HORSE_TRAILER: 'Large Horse Trailer',
  UNENCLOSED_UTILITY_TRAILER_13_PLUS_FT: 'Unenclosed Utility Trailer (13+ ft)',
  ENCLOSED_UTILITY_TRAILER_13_PLUS_FT: 'Enclosed Utility Trailer (13+ ft)',
  CONCESSION_TRAILER: 'Concession Trailer',
  TRAVEL_TRAILER: 'Travel Trailer',
  LOGGING_TRAILER: 'Logging Trailer',
  GOOSENECK_TRAILER: 'Gooseneck Trailer',
  SPORT_AUTO: 'Sport Auto',
  LUXURY_AUTO: 'Luxury Auto',
  PRIVATE_PASSENGER_AUTO: 'Private Passenger Auto',
  MOTOR_HOME: 'Motor Home',
  UTILITY_VEHICLE: 'Utility Vehicle',
  LUXURY_SUV: 'Luxury SUV',
  LIMOUSINE_0_120_INCHES: 'Limousine (0-120 inches)',
  HEARSE: 'Hearse',
  LIMOUSINE_121_180_INCHES: 'Limousine (121-180 inches)',
  LIMOUSINE_181_PLUS_INCHES: 'Limousine (181+ inches)',
};

export enum CommercialAutoVehicleSecurementType {
  TOW_HITCH = 'TOW_HITCH',
  TOW_HOOK = 'TOW_HOOK',
  TIRE_STRAPS = 'TIRE_STRAPS',
  RATCHET_STRAPS = 'RATCHET_STRAPS',
  CHAINS = 'CHAINS',
  WHEEL_CHOCKS = 'WHEEL_CHOCKS',
  WEIGHT_BALANCED_ACROSS_AXLES = 'WEIGHT_BALANCED_ACROSS_AXLES',
  OTHER = 'OTHER',
}

export const CommercialAutoVehicleSecurementTypeDisplay: Record<
  CommercialAutoVehicleSecurementType,
  string
> = {
  TOW_HITCH: 'Tow Hitch',
  TOW_HOOK: 'Tow Hook',
  TIRE_STRAPS: 'Tire Straps',
  RATCHET_STRAPS: 'Ratchet Straps',
  CHAINS: 'Chains',
  WHEEL_CHOCKS: 'Wheel Chocks',
  WEIGHT_BALANCED_ACROSS_AXLES: 'Weight Balanced Across Axles',
  OTHER: 'Other',
};

export enum PartyVehicleConnectionType {
  HITCHED = 'HITCHED',
  LOADED = 'LOADED',
}

export enum CommercialAutoVehicleOwnership {
  INSURED_OWNED = 'INSURED_OWNED',
  INSURED_HIRED = 'INSURED_HIRED',
  INSURED_WORKER_OWNED_OR_HIRED = 'INSURED_WORKER_OWNED_OR_HIRED',
  SOMEONE_ELSE_OWNED = 'SOMEONE_ELSE_OWNED',
  DONT_KNOW = 'DONT_KNOW',
}

export interface PartyVehicleConnection {
  id: string;
  createdAt?: Date | null;
  updatedAt?: Date | null;
  transportingInvolvedPartyId?: string | null;
  transportedInvolvedPartyId?: string | null;
  transportingPartyId?: string | null;
  transportedPartyId?: string | null;
  connectionType: PartyVehicleConnectionType;
}

export interface CommercialAutoVehicle {
  id: string;
  createdAt?: Date | null;
  updatedAt?: Date | null;
  type?: CommercialAutoVehicleType | null;
  ownership?: CommercialAutoVehicleOwnership | null;
  ownerCommercialAutoPartyId?: string | null;
  cargoId?: string | null;
  involvedPartyId?: string | null;
  billOfLadingMediaId?: string | null;
  trailerInterchangeAgreementMediaId?: string | null;
  permanentLeaseAgreementMediaId?: string | null;
  securementTypes: CommercialAutoVehicleSecurementType[];
  garagekeeperVehicleDamageContexts: CommercialAutoGaragekeeperVehicleDamageContexts[];
}

export enum CommercialAutoCargoType {
  INTERMODAL_CONTAINERS = 'INTERMODAL_CONTAINERS',
  REFRIGERATED_CARGO = 'REFRIGERATED_CARGO',
  LIQUID_BULK = 'LIQUID_BULK',
  DRY_BULK = 'DRY_BULK',
  BREAKBULK = 'BREAKBULK',
  VEHICLES = 'VEHICLES',
  LIVESTOCK = 'LIVESTOCK',
  RO_RO = 'RO_RO',
  HAZARDOUS_MATERIALS = 'HAZARDOUS_MATERIALS',
  JUNK_DEBRIS = 'JUNK_DEBRIS',
  SOMETHING_ELSE = 'SOMETHING_ELSE',
  DONT_KNOW = 'DONT_KNOW',
}

export enum CommercialAutoCargoWeight {
  LESS_THAN_1000_LBS = 'LESS_THAN_1000_LBS',
  ONE_TO_TEN_THOUSAND_LBS = 'ONE_TO_TEN_THOUSAND_LBS',
  TEN_TO_FIFTY_FIVE_THOUSAND_LB = 'TEN_TO_FIFTY_FIVE_THOUSAND_LB',
  FIFTY_FIVE_THOUSAND_PLUS = 'FIFTY_FIVE_THOUSAND_PLUS',
}

export const CommercialAutoCargoWeightDisplay: Record<
  CommercialAutoCargoWeight,
  string
> = {
  LESS_THAN_1000_LBS: 'Less than 1,000 lbs',
  ONE_TO_TEN_THOUSAND_LBS: '1,000 - 10,000 lbs',
  TEN_TO_FIFTY_FIVE_THOUSAND_LB: '10,000 - 55,000 lbs',
  FIFTY_FIVE_THOUSAND_PLUS: '55,000 lbs or more',
};

export enum CargoSpoilageReason {
  MECHANICAL_FAILURE = 'MECHANICAL_FAILURE',
  POWER_OUTAGE = 'POWER_OUTAGE',
  INADEQUATE_PACKAGING = 'INADEQUATE_PACKAGING',
  TRANSPORTATION_DELAYS = 'TRANSPORTATION_DELAYS',
  NATURAL_SPOILAGE = 'NATURAL_SPOILAGE',
  OTHER = 'OTHER',
}

export enum CargoRefrigerationFailedPartType {
  REFRIGERATION_UNIT = 'REFRIGERATION_UNIT',
  TEMPERATURE_SENSOR = 'TEMPERATURE_SENSOR',
  INSULATION = 'INSULATION',
  OTHER = 'OTHER',
}

export enum CargoRefrigerationPackagingIssue {
  INSUFFICIENT_INSULATION = 'INSUFFICIENT_INSULATION',
  INSUFFICIENT_REFRIGERANT = 'INSUFFICIENT_REFRIGERANT',
  OTHER = 'OTHER',
}

export enum WhoPackagedCargo {
  SHIPPER = 'SHIPPER',
  CARRIER = 'CARRIER',
  MANUFACTURER = 'MANUFACTURER',
  DONT_KNOW = 'DONT_KNOW',
}

export enum CargoTransportationDelayReason {
  TRAFFIC = 'TRAFFIC',
  WEATHER = 'WEATHER',
  MECHANICAL_FAILURE = 'MECHANICAL_FAILURE',
  ACCIDENT = 'ACCIDENT',
  OTHER = 'OTHER',
}

export enum CommercialAutoCargoOwnership {
  INSURED_OWNED = 'INSURED_OWNED',
  SHIPPER_OWNED = 'SHIPPER_OWNED',
  CONSIGNEE_OWNED = 'CONSIGNEE_OWNED',
  SOMEONE_ELSE_OWNED = 'SOMEONE_ELSE_OWNED',
  DONT_KNOW = 'DONT_KNOW',
}

export interface CommercialAutoCargo {
  id: string;
  createdAt?: Date | null;
  updatedAt?: Date | null;
  type?: CommercialAutoCargoType | null;
  typeOther?: string | null;
  weight?: CommercialAutoCargoWeight | null;
  estimatedValueUSCents?: number | null;
  spoiled?: boolean | null;
  damaged?: boolean | null;
  damageDescription?: string | null;
  cargoSpoilageReason?: CargoSpoilageReason | null;
  refrigerationFailedPartType?: CargoRefrigerationFailedPartType | null;
  refrigerationPackagingIssue?: CargoRefrigerationPackagingIssue | null;
  whoPackagedCargo?: WhoPackagedCargo | null;
  powerOutageDurationHours?: number | null;
  transportationDelayReason?: CargoTransportationDelayReason | null;
  transportationDelayDurationHours?: number | null;
  ownership?: CommercialAutoCargoOwnership | null;
  ownerCommercialAutoPartyId?: string | null;
}

export interface InvolvedPartyType {
  id?: string;
  type: string;

  insuredName?: string;
  insuredVehicle?: VehicleType;

  transportedConnections: PartyVehicleConnection[];
  transportingConnections: PartyVehicleConnection[];

  commercialAutoVehicle?:
    | (CommercialAutoVehicle & { cargo?: CommercialAutoCargo })
    | null;

  agency?: string;
  agencyPolicyNumber?: string;
  agencyPolicyExpiration?: string;
  agencyPhoneNumber?: string;
  agencyNaic?: string;

  userEnteredDescription?: string;

  personName?: string;
  personPhoneNumber?: string;

  userContent?: string;
  // FIXME(01-20-2020) JSON type
  // FIXME(01-20-2020) Revamp for standardized fields

  insuranceCardDocument?: DocumentType;
  driverLicenseDocument?: DocumentType;

  licensePlateMedia?: MediaType;

  manualVehicleLicensePlate?: string;
  manualVehicleLicensePlateState?: string;

  incidentDirectionOfTravel?: number;

  passengers: PassengerType[];

  vehicleMovementState?: string;

  airbagsDeployed: boolean;
  drivableAfterIncident: boolean;
  wheelsDamaged: boolean;
  doorsDamaged: boolean;
  fluidsLeaking: boolean;
  leftRoadway: boolean;

  vehicleWasDamaged?: boolean;
  vehicleDamageMesh?: string;
  vehicleDamageRegions?: { region: string }[];

  driverWasInjured?: boolean;
  driverInjurySeverity?: string;
  driverInjuryNightsHospitalized?: number;
  driverBodilyInjuryData?: string;

  vehicleHadPriorDamage?: boolean;
  vehiclePriorDamageMesh?: string;
  vehiclePriorDamageRegions?: { region: string }[];

  repairEstimateAmount?: number;
  rentalIntent?: RentalIntent;

  theftVehicleWasRecovered?: boolean;
  theftVehicleWasCarjacking?: boolean;
  theftVehicleWasAttemptedOnly?: boolean;
  vehicleRecoveryLocation?: LocationType;

  tripDepartedFromLocation?: LocationType;
  tripHeadingToLocation?: LocationType;
  tripWasRideshareOrDelivery?: boolean;
  tripWasRideshare?: boolean;
  tripWasDelivery?: boolean;
  tripWasCarshare?: boolean;
  tripWasWorkRelated?: boolean;
  tripWasWorkCommute?: boolean;
  tripWorkType?: TripWorkType;

  userOccupant: {
    // more properties exist in actuality, so we should populate them
    // here on an "as-needed" basis
    attorney?: {
      name?: string;
      company?: string;
      phoneNumber?: string;
    };
    name?: string;
    medicalProviderOrganizations?: MedicalProviderOrganizationType[];
    contactAddress?: LocationType;
    seat?: VehicleOccupantSeat;
  };

  pedestrianContact: {
    medicalProviderOrganizations?: MedicalProviderOrganizationType[];
    contactAddress?: LocationType;
    wasInjured?: boolean;
    injurySeverity?: string;
    injuryNightsHospitalized?: number;
    bodilyInjuryData?: string;
  };

  vehicleRequestedBodyShop?: string;
  vehicleBodyShopState?: string;
  vehicleBodyShopLocation?: LocationType;

  rentalRequestedPickupLocation?: string;
  carSeatUsed?: boolean;
  seatsWithChildCarSeats?: VehicleOccupantSeat[];
  childCarSeats?: ChildCarSeat[];
  lawsuitWasFiled?: boolean;
  nameOfCouncilForLawsuit?: string;
  repairIntent?: RepairIntent;
  wasStruckInAnimalInteraction?: boolean;
  wasInvolvedInAnimalInteraction?: boolean;
  objectIncidentInteractionType?: string;

  wasPetPresentInVehicle?: boolean;
  wasPetInjuredInIncident?: boolean;
}
