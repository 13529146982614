import { DetailedHTMLProps } from 'react';

type HtmlSvgProps = DetailedHTMLProps<
  React.SVGAttributes<SVGSVGElement>,
  SVGSVGElement
>;

export const IconEmojiSemiTrailer = (props: HtmlSvgProps) => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <g clipPath="url(#clip0_719_495)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 86H14V116H11V117H14H17H20V116H17V86Z"
          fill="url(#paint0_linear_719_495)"
        />
        <path d="M17 104.5L27 85" stroke="url(#paint1_linear_719_495)" />
        <path d="M14 104.5L4 85" stroke="url(#paint2_linear_719_495)" />
        <rect
          x="13"
          y="103"
          width="5"
          height="3"
          fill="url(#paint3_linear_719_495)"
        />
        <rect
          x="13"
          y="86"
          width="5"
          height="3"
          fill="url(#paint4_linear_719_495)"
        />
        <rect
          x="4"
          y="86"
          width="4"
          height="2"
          fill="url(#paint5_linear_719_495)"
        />
        <rect
          x="23"
          y="86"
          width="4"
          height="2"
          fill="url(#paint6_linear_719_495)"
        />
        <rect
          x="122"
          y="86"
          width="2"
          height="16"
          fill="url(#paint7_linear_719_495)"
        />
        <rect
          x="121"
          y="86"
          width="4"
          height="2"
          fill="url(#paint8_linear_719_495)"
        />
        <path d="M0 25H128V86H0V25Z" fill="url(#paint9_linear_719_495)" />
        <g style={{ mixBlendMode: 'overlay', opacity: '0.5' }}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 25H13V86H12V25ZM25 25H26V86H25V25ZM39 25H38V86H39V25ZM51 25H52V86H51V25ZM65 25H64V86H65V25ZM77 25H78V86H77V25ZM91 25H90V86H91V25ZM103 25H104V86H103V25ZM117 25H116V86H117V25Z"
            fill="black"
          />
        </g>
        <path
          d="M1 26H127V85H1V26Z"
          stroke="url(#paint10_linear_719_495)"
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41 86H39V90H41V95H39V99H41V101C41 101.552 41.4477 102 42 102H111C111.552 102 112 101.552 112 101V99H114V95H112V90H114V86H112H41Z"
          fill="url(#paint11_linear_719_495)"
        />
        <rect opacity="0.25" x="41" y="90" width="71" height="1" fill="black" />
        <rect opacity="0.25" x="41" y="99" width="71" height="1" fill="black" />
        <g filter="url(#filter0_i_719_495)">
          <path
            d="M46 102.653V105.352H75.2372V102.653C75.2372 94.5603 68.6922 88 60.6186 88C52.545 88 46 94.5603 46 102.653Z"
            fill="url(#paint12_linear_719_495)"
          />
        </g>
        <circle
          cx="60.6185"
          cy="103.253"
          r="13.423"
          fill="url(#paint13_radial_719_495)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.6184 111.497C65.1717 111.497 68.8629 107.806 68.8629 103.252C68.8629 98.699 65.1717 95.0078 60.6184 95.0078C56.065 95.0078 52.3738 98.699 52.3738 103.252C52.3738 107.806 56.065 111.497 60.6184 111.497ZM60.6185 100.282C61.4531 100.282 62.1842 99.2773 62.5901 97.7717C61.9743 97.5501 61.3104 97.4294 60.6184 97.4294C59.9264 97.4294 59.2626 97.5501 58.6469 97.7716C59.0527 99.2773 59.7839 100.282 60.6185 100.282ZM63.1908 101.767C62.7736 101.045 63.2777 99.9095 64.378 98.8056C65.3906 99.6626 66.1065 100.859 66.35 102.22C64.8434 102.621 63.608 102.49 63.1908 101.767ZM63.1908 104.738C63.608 104.015 64.8434 103.884 66.35 104.285C66.1065 105.645 65.3905 106.842 64.3779 107.699C63.2777 106.595 62.7736 105.46 63.1908 104.738ZM60.6185 106.222C61.4531 106.222 62.1843 107.227 62.5901 108.733C61.9743 108.954 61.3104 109.075 60.6184 109.075C59.9264 109.075 59.2626 108.954 58.6469 108.733C59.0527 107.227 59.7839 106.222 60.6185 106.222ZM58.0461 104.738C58.4633 105.46 57.9592 106.595 56.8589 107.699C55.8463 106.842 55.1303 105.646 54.8868 104.285C56.3934 103.884 57.6289 104.015 58.0461 104.738ZM58.0461 101.767C57.6288 102.49 56.3934 102.621 54.8868 102.22C55.1302 100.859 55.8462 99.6625 56.8588 98.8055C57.9591 99.9095 58.4632 101.045 58.0461 101.767Z"
          fill="url(#paint14_linear_719_495)"
        />
        <g filter="url(#filter1_i_719_495)">
          <path
            d="M78 102.653V105.352H104.656H107.238V102.653C107.238 94.5604 100.693 88 92.6188 88C84.5451 88 78 94.5604 78 102.653Z"
            fill="url(#paint15_linear_719_495)"
          />
        </g>
        <circle
          cx="92.6189"
          cy="103.253"
          r="13.4231"
          fill="url(#paint16_radial_719_495)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M92.3222 111.497C96.8756 111.497 100.567 107.806 100.567 103.252C100.567 98.699 96.8756 95.0078 92.3222 95.0078C87.7689 95.0078 84.0776 98.699 84.0776 103.252C84.0776 107.806 87.7689 111.497 92.3222 111.497ZM92.3223 100.282C93.1569 100.282 93.8881 99.2773 94.2939 97.7717C93.6782 97.5501 93.0143 97.4294 92.3222 97.4294C91.6302 97.4294 90.9664 97.5501 90.3508 97.7716C90.7566 99.2773 91.4878 100.282 92.3223 100.282ZM94.8946 101.767C94.4775 101.045 94.9816 99.9095 96.0819 98.8056C97.0944 99.6626 97.8104 100.859 98.0538 102.22C96.5473 102.621 95.3119 102.49 94.8946 101.767ZM94.8946 104.738C95.3118 104.015 96.5473 103.884 98.0538 104.285C97.8103 105.645 97.0944 106.842 96.0818 107.699C94.9815 106.595 94.4775 105.46 94.8946 104.738ZM92.3223 106.222C93.1569 106.222 93.8881 107.227 94.2939 108.733C93.6782 108.954 93.0143 109.075 92.3222 109.075C91.6302 109.075 90.9664 108.954 90.3507 108.733C90.7565 107.227 91.4877 106.222 92.3223 106.222ZM89.75 104.738C90.1671 105.46 89.663 106.595 88.5628 107.699C87.5501 106.842 86.8342 105.646 86.5906 104.285C88.0972 103.884 89.3327 104.015 89.75 104.738ZM89.7499 101.767C89.3327 102.49 88.0972 102.621 86.5906 102.22C86.8341 100.859 87.5501 99.6625 88.5627 98.8055C89.663 99.9095 90.1671 101.045 89.7499 101.767Z"
          fill="url(#paint17_linear_719_495)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_719_495"
          x="46"
          y="88"
          width="29.2373"
          height="18.3521"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_719_495"
          />
        </filter>
        <filter
          id="filter1_i_719_495"
          x="78"
          y="88"
          width="29.2375"
          height="18.3525"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_719_495"
          />
        </filter>
        <linearGradient
          id="paint0_linear_719_495"
          x1="15"
          y1="117"
          x2="15"
          y2="90.0565"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_719_495"
          x1="23"
          y1="85"
          x2="23"
          y2="104.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_719_495"
          x1="10"
          y1="85"
          x2="10"
          y2="104.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_719_495"
          x1="15.2222"
          y1="106"
          x2="15.2222"
          y2="103.393"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_719_495"
          x1="15.2222"
          y1="89"
          x2="15.2222"
          y2="86.3926"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_719_495"
          x1="5.77778"
          y1="88"
          x2="5.77778"
          y2="86.2617"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_719_495"
          x1="24.7778"
          y1="88"
          x2="24.7778"
          y2="86.2617"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_719_495"
          x1="123"
          y1="102"
          x2="123"
          y2="88.0937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_719_495"
          x1="122.778"
          y1="88"
          x2="122.778"
          y2="86.2617"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_719_495"
          x1="64"
          y1="25"
          x2="64"
          y2="86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDEDED" />
          <stop offset="1" stopColor="#CCCCCC" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_719_495"
          x1="64"
          y1="25"
          x2="64"
          y2="86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDCDCD" />
          <stop offset="1" stopColor="#9F9F9F" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_719_495"
          x1="76.5"
          y1="102"
          x2="76.5"
          y2="88.0937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#303030" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_719_495"
          x1="60.6186"
          y1="91.878"
          x2="60.6186"
          y2="105.352"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.776042" stopColor="#1D1D1D" />
          <stop offset="1" stopColor="#262626" />
        </linearGradient>
        <radialGradient
          id="paint13_radial_719_495"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(60.6185 103.253) rotate(90) scale(13.423)"
        >
          <stop offset="0.65625" stopColor="#393939" />
          <stop offset="0.786458" stopColor="#4D4D4D" />
          <stop offset="1" stopColor="#1E1E1E" />
        </radialGradient>
        <linearGradient
          id="paint14_linear_719_495"
          x1="60.3107"
          y1="94.0949"
          x2="60.1825"
          y2="111.496"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#ACACAC" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_719_495"
          x1="92.6188"
          y1="91.8781"
          x2="92.6188"
          y2="105.352"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.776042" stopColor="#1D1D1D" />
          <stop offset="1" stopColor="#262626" />
        </linearGradient>
        <radialGradient
          id="paint16_radial_719_495"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(92.6189 103.253) rotate(90) scale(13.4231)"
        >
          <stop offset="0.65625" stopColor="#393939" />
          <stop offset="0.786458" stopColor="#4D4D4D" />
          <stop offset="1" stopColor="#1E1E1E" />
        </radialGradient>
        <linearGradient
          id="paint17_linear_719_495"
          x1="92.0145"
          y1="94.0949"
          x2="91.8864"
          y2="111.496"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#ACACAC" />
        </linearGradient>
        <clipPath id="clip0_719_495">
          <rect width="128" height="128" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconEmojiSemiTrailer;
