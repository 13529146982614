/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import Fuse from 'fuse.js';
import React, {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactSelect, {
  ClassNamesConfig,
  ClearIndicatorProps,
  components as ReactSelectComponents,
  InputProps,
  OptionProps,
  Props as ReactSelectProps,
} from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select/dist/declarations/src/Select';
import { twMerge } from 'tailwind-merge';

import { CustomSelectValue } from '@assured/step-renderer/types/step-components/additional';

import { HighlightedText } from '../HighlightedText';
import { IconFlatCheck } from '../Icon/IconFlatCheck';
import { IconFlatCircleXSharp } from '../Icon/IconFlatCircleXSharp';
import { IconFlatHighwayShield } from '../Icon/IconFlatHighwayShield';
import { EmotionStyleSheetProvider } from './EmotionStyleSheetProvider';
import { isSelectOptionsList, SelectDropdownWithTabsOption } from './types';

import type { FilterOption, SelectGroups, SelectOption } from './types';

export interface LabelProps {
  labelStr?: string;
  htmlFor?: string;
  className?: string;
  optional?: boolean;
  required?: boolean;
}

export type Options = ReactSelectProps['options'];

export interface SelectDropdownProps
  extends Pick<
    ReactSelectProps,
    | 'autoFocus'
    | 'className'
    | 'classNames'
    | 'components'
    | 'defaultMenuIsOpen'
    | 'filterOption'
    | 'formatOptionLabel'
    | 'inputValue'
    | 'inputValue'
    | 'isClearable'
    | 'isLoading'
    | 'isSearchable'
    | 'maxMenuHeight'
    | 'menuIsOpen'
    | 'menuPlacement'
    | 'menuPosition'
    | 'minMenuHeight'
    | 'onBlur'
    | 'onInputChange'
    | 'onKeyDown'
    | 'openMenuOnClick'
    | 'placeholder'
    | 'required'
    | 'styles'
    | 'value'
    | 'menuPortalTarget'
  > {
  closeMenuOnSelect?: boolean;
  disabled?: boolean;
  error?: string;
  groupCategories?: string[];
  groupedOptions?: SelectGroups[];
  inline?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  labelProps?: LabelProps;
  loadOptions?: (inputValue: string) => Promise<Options[]>;
  onChange?: (v: { value: CustomSelectValue } | null) => void;
  openMenuOnClick?: boolean;
  openMenuOnFocus?: boolean;
  options: SelectOption[] | SelectGroups[];
  selectedGroupCategory?: string;
  selectGroupCategory?: (category: string) => void;
  useDefaultFilter?: boolean;
  variant?: 'select' | 'create' | 'asyncCreate';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  theme?: 'highway';
  otherOptionValue?: SelectOption['value'];
  minQueryLength?: number;
  shouldSortByScore?: boolean;
  editableInput?: boolean;
  editableInputIncompleteErrorText?: string;
  ClearIndicatorCallback?: () => void;
  menuPortalZIndex?: number;
}

const getDesignSystemClassnames = ({
  size,
  inline,
  disabled,
  theme,
  error,
}: Pick<
  SelectDropdownProps,
  'size' | 'inline' | 'disabled' | 'theme' | 'error'
>): ClassNamesConfig<unknown> => ({
  dropdownIndicator: () => {
    return classNames(
      size === 'sm' &&
        'h-[15px] w-[15px] !p-0 !ml-2 !text-sm font-thin !rounded-lg items-center',
    );
  },
  menu: () => {
    return classNames(
      '!border !border-cool-gray-300 !rounded-lg !shadow-[0_3px_8px_rgba(0,0,0,0.15)] min-w-full',
      size === 'xl' && 'p-1',
      size !== 'xl' && '!mt-1 p-0',
      inline && '!mt-0',
    );
  },
  option: state => {
    return classNames(
      'rounded-md w-max !text-cool-gray-600',
      size === 'xl' && '!px-2',
      size !== 'xl' && '!text-sm !p-3 !bg-white hover:!bg-cool-gray-50',
      (state.isSelected || state.isFocused) && '!text-black',
      state.isSelected && size === 'xl' && '!bg-indigo-100/90',
      state.isFocused && '!bg-cool-gray-100/90',
    );
  },
  menuList: () => {
    return '!pb-0 !pt-0 text-lg rounded-lg';
  },
  input: () => {
    return classNames(`!text-cool-gray-600`, size === 'xl' && 'text-lg');
  },
  valueContainer: () => {
    return classNames(
      '!text-cool-gray-600',
      size === 'xl' && `text-lg`,
      size === 'sm' && `text-sm !p-0`,
      size === 'md' && `text-sm !p-0`,
      size === 'lg' && `text-md !p-0`,
    );
  },
  singleValue: () => {
    return classNames(size === 'xl' && `text-lg !text-cool-gray-600`);
  },
  control: state => {
    return inline
      ? 'text-lg !p-0 !border-transparent focus:!border-transparent !shadow-none [&_>_*]:!px-0'
      : classNames(
          '!rounded-lg',
          size === 'xl' && 'hover:!shadow-lightindigo',
          size !== 'xl' &&
            !state.isFocused &&
            !state.menuIsOpen &&
            'hover:!border-gray-400',
          disabled ? '!bg-cool-gray-100' : '!bg-white',
          error && '!border-red-500',
          state.isFocused && '!ring-indigo-600 !border-indigo-600',
          state.isFocused && size !== 'xl' && '!ring-0 !shadow-lightindigo',
          state.isFocused && size === 'xl' && '!ring-[1.33px]',
          state.menuIsOpen && size === 'xl' && 'ring-1 drop-shadow-md',
          state.menuIsOpen && size === 'sm' && 'drop-shadow-sm',
          state?.menuIsOpen &&
            !error &&
            '!border !border-indigo-600 ring-indigo-600',
          state?.menuIsOpen && error && '!border !border-red-600 !ring-red-600',
          !state?.menuIsOpen &&
            '!border !border-cool-gray-300 !shadow-none !drop-shadow-none !ring-0',
          {
            'px-2': theme !== 'highway',
            'pl-12 pr-2': theme === 'highway',
          },
          size === 'sm' && '!py-0 px-3 !min-h-[32px]',
          size === 'md' && '!py-[1px] px-3',
          size === 'lg' && '!py-2.5',
          size === 'xl' && '!py-2.5',
        );
  },
  placeholder: () => {
    return classNames('!text-cool-gray-400', size === 'xl' && '!text-lg');
  },
});

export const SelectDropdown = forwardRef<ReactSelect, SelectDropdownProps>(
  (
    {
      autoFocus = false,
      closeMenuOnSelect = true,
      components,
      defaultMenuIsOpen = false,
      disabled = false,
      error,
      filterOption,
      formatOptionLabel,
      groupCategories,
      groupedOptions,
      inline,
      inputProps,
      inputValue,
      isClearable = true,
      isLoading = false,
      isSearchable = true,
      labelProps,
      loadOptions,
      maxMenuHeight,
      menuPlacement: menuPlacementOverride,
      menuPosition,
      minMenuHeight,
      onBlur,
      onChange,
      onInputChange,
      onKeyDown,
      openMenuOnClick,
      openMenuOnFocus,
      options,
      placeholder,
      required,
      selectedGroupCategory,
      selectGroupCategory,
      styles: reactSelectStyles,
      useDefaultFilter,
      value,
      variant = 'select',
      size = 'xl',
      menuPortalTarget,
      theme,
      otherOptionValue,
      className,
      minQueryLength = 2,
      shouldSortByScore = false,
      editableInput = false,
      editableInputIncompleteErrorText,
      ClearIndicatorCallback,
      menuPortalZIndex = 1, // default
      classNames: reactSelectClassNames,
    },
    ref,
  ) => {
    let Component: ReactSelect | AsyncCreatableSelect | CreatableSelect =
      ReactSelect;

    if (variant === 'asyncCreate') {
      Component = AsyncCreatableSelect;
    } else if (variant === 'create') {
      Component = CreatableSelect;
    }

    const fuse = useMemo(() => {
      return new Fuse<SelectDropdownWithTabsOption>([], {
        keys: ['label', 'sublabel', 'value'],
        includeScore: true,
        minMatchCharLength: minQueryLength,
        threshold: 0.3,
        shouldSort: shouldSortByScore,
      });
    }, [minQueryLength, shouldSortByScore]);

    const [query, setQuery] = useState<string | undefined>();

    const { dropdownIndicator: dropdownIndicatorStyles, ...styles } =
      reactSelectStyles || {};

    useEffect(() => {
      fuse.setCollection(
        'value' in ((options[0] || {}) as SelectOption)
          ? (options as SelectOption[])
          : options.flatMap(o => (o as SelectGroups).options),
      );
    }, [fuse, options]);

    // In editableInput mode, every time the value changes (to an actual set value),
    // reset the query so we show the latest value to edit in the input.
    useEffect(() => {
      if (editableInput && value) {
        setQuery(undefined);
      }
    }, [editableInput, value]);

    // In editableInput mode, if the user has blurred the input without choosing a value,
    // trigger an error state to make it clear to the user that they have not actually selected
    // the value that the input is showing.
    const [hasBlurred, setHasBlurred] = useState(false);
    const showIncompleteError =
      editableInput && hasBlurred && !value && !!query;
    if (showIncompleteError) {
      error =
        editableInputIncompleteErrorText ?? `Select an option to continue.`;
    }

    const { results, exactMatch } = useMemo(() => {
      const searchResults = fuse.search(query ?? '');

      return {
        results: searchResults,
        exactMatch: searchResults.find(
          // Fuse returns a score of <= epsilon for exact matches.
          // Ref: https://github.com/krisk/Fuse/blob/43eebfaa35917217848958e0ccc811ca07026737/src/core/computeScore.js#L15
          r => typeof r.score !== 'undefined' && r.score <= Number.EPSILON,
        ),
      };
    }, [fuse, query]);

    const fuzzyFilter = useCallback(
      (option: FilterOption<unknown>, input: string): boolean => {
        if (!input || !fuse) return true; // Allow all options if no input or no fuse instance

        if (input.length < minQueryLength) return false;

        // eslint-disable-next-line no-underscore-dangle
        if ((option as FilterOption<SelectOption>)?.data?.__isNew__) {
          // If the option is a new option, return true
          return true;
        }

        // If the option is the "other" option, return true
        if (otherOptionValue && option.value === otherOptionValue) {
          return true;
        }

        // If there is an exact match, only return it
        if (exactMatch && results.length > 0) {
          return option.value === exactMatch.item.value;
        }

        if (results.length > 0) {
          return results.some(match => match.item.value === option.value);
        }

        // If no matches and variant is 'create', only allow the creation of a new option
        if (results.length === 0 && variant === 'create') {
          return false; // This will hide all other options except the "create [option]"
        }

        return false;
      },
      [fuse, variant, results, exactMatch, minQueryLength, otherOptionValue],
    );

    const sortedOptions = useMemo(() => {
      // If shouldSortByScore is true, sort the options by the score of the search results
      // with the matched options first, followed by the other options
      if (shouldSortByScore && options.length && 'value' in options[0]) {
        const matchedOptions = results.map(r => options[r.refIndex]);
        const otherOptions = options.filter(o => !matchedOptions.includes(o));

        return [...matchedOptions, ...otherOptions];
      }

      return options;
    }, [shouldSortByScore, options, results]);

    const controlComponent = useCallback(
      props => {
        return (
          <>
            {theme === 'highway' ? (
              <div className="absolute top-[1px] left-[1px] z-[1] py-[13.5px] px-[7px] w-fit rounded-l-md bg-[#241F20] bg-[linear-gradient(180deg,rgba(255,255,255,0.16)_0%,rgba(217,217,217,0)_100%)]">
                <IconFlatHighwayShield className="w-8 h-8" />
              </div>
            ) : null}
            <ReactSelectComponents.Control
              {...props}
              data-id="select-dropdown-control"
            />
          </>
        );
      },
      [theme],
    );

    const selectTop =
      (ref as RefObject<Select>)?.current?.inputRef?.getBoundingClientRect()
        ?.top ?? 0;
    const offsetBasedMenuPlacement =
      window.innerHeight - selectTop < 400 ? 'top' : 'auto';

    const classNamesProp =
      reactSelectClassNames ??
      getDesignSystemClassnames({
        size,
        inline,
        error,
        theme,
        disabled,
      });

    return (
      <EmotionStyleSheetProvider>
        <label
          htmlFor={labelProps?.htmlFor}
          className={`flex flex-col gap-1 ${className}`}
        >
          {labelProps?.labelStr ? (
            <span
              className={twMerge(
                'mb-3 text-lg font-medium text-cool-gray-500',
                labelProps?.className,
              )}
            >
              {labelProps?.labelStr}
              {required ? <span className="text-red-500">*</span> : null}
            </span>
          ) : null}

          <Component
            autoFocus={autoFocus}
            ref={ref as any}
            components={{
              ClearIndicator: ClearIndicator(ClearIndicatorCallback),
              Input: editableInput ? EditableInput : Input,
              IndicatorSeparator: () => null,
              Option: size === 'xl' ? (Option as any) : (SmallOption as any),
              Control: controlComponent,
              ...components,
            }}
            closeMenuOnSelect={closeMenuOnSelect}
            data-id="select-dropdown"
            defaultMenuIsOpen={defaultMenuIsOpen}
            groupCategories={groupCategories}
            isLoading={isLoading}
            filterOption={
              useDefaultFilter ? undefined : filterOption || fuzzyFilter
            }
            {...inputProps}
            formatOptionLabel={formatOptionLabel}
            inputValue={
              editableInput
                ? typeof query !== 'undefined'
                  ? query
                  : (value as string)
                : inputValue
            }
            isClearable={isClearable}
            isDisabled={disabled}
            isSearchable={isSearchable}
            loadOptions={loadOptions}
            maxMenuHeight={maxMenuHeight}
            minMenuHeight={minMenuHeight}
            menuPosition={menuPosition ?? 'fixed'}
            menuPlacement={menuPlacementOverride ?? offsetBasedMenuPlacement}
            menuShouldScrollIntoView={false}
            menuPortalTarget={
              menuPortalTarget ||
              document?.getElementById('root') /* to avoid clipping */
            }
            openMenuOnFocus={openMenuOnFocus}
            openMenuOnClick={openMenuOnClick}
            placeholder={placeholder}
            onBlur={e => {
              if (editableInput) {
                setHasBlurred(true);
              }
              onBlur?.(e);
            }}
            onChange={(v: any) => {
              if (v === null && editableInput) {
                // Necessary to force react-select to render an empty input and not display a lingering
                // stale value from the initial selection.
                setQuery('');
              }
              onChange?.(v);
            }}
            onInputChange={(newValue, actionMeta) => {
              if (editableInput) {
                if (
                  actionMeta.action === 'input-blur' ||
                  actionMeta.action === 'menu-close'
                ) {
                  // Persist the user's input query if they click out of the dropdown
                  // by exiting early and not setting query to an empty string.
                  // Allows them to keep editing their query without losing it.
                  return;
                }

                // They've interacted, so we can consider the input as active again and
                // dismiss the error state.
                setHasBlurred(false);

                if (value && newValue !== value) {
                  // If editing, unset the current value in order to prevent the user
                  // from submitting with an incomplete address.
                  onChange?.(null);
                }
              }

              setQuery(newValue);

              onInputChange?.(newValue, actionMeta);
            }}
            onKeyDown={onKeyDown}
            options={sortedOptions}
            selectedGroupCategory={selectedGroupCategory}
            selectGroupCategory={selectGroupCategory}
            value={
              editableInput
                ? value
                  ? { label: value, value }
                  : null
                : value
                ? isSelectOptionsList(options)
                  ? options?.find(o => {
                      return o?.value === value;
                    }) ?? {
                      label: value,
                      value,
                    }
                  : /**
                     * options are collections of grouped options with a .options property
                     * to determine current value, need to look at ALL the options
                     * not just slice of `options` passed to Select
                     */
                    groupedOptions
                      ?.flatMap(oo => {
                        return oo?.options;
                      })
                      .find(oo => {
                        return oo.value === value;
                      })
                : undefined
            }
            styles={{
              dropdownIndicator: (provided, state) => ({
                ...provided,
                transform: state?.selectProps?.menuIsOpen
                  ? 'rotate(180deg)'
                  : 'none',
                transition: 'transform 0.2s ease',
                ...(!dropdownIndicatorStyles
                  ? {}
                  : dropdownIndicatorStyles(provided, state)),
              }),
              menuPortal: provided => ({
                ...provided,
                zIndex: menuPortalZIndex,
              }),
              ...styles,
            }}
            classNames={classNamesProp}
          />
          {error && <div className="text-xs text-red-600">{error}</div>}
        </label>
      </EmotionStyleSheetProvider>
    );
  },
);

const ClearIndicator = (callback?: () => void) => {
  return ({ innerProps }: ClearIndicatorProps) => {
    const { onMouseDown, onTouchEnd } = innerProps;

    return (
      <div
        role="button"
        tabIndex={-1}
        onMouseDown={e => {
          if (onMouseDown) {
            onMouseDown(e);
          }
          if (callback) {
            callback();
          }
        }}
        onTouchEnd={e => {
          if (onTouchEnd) {
            onTouchEnd(e);
          }
          if (callback) {
            callback();
          }
        }}
      >
        <IconFlatCircleXSharp className="text-gray-400 hover:text-gray-500" />
      </div>
    );
  };
};

const Option = (props: OptionProps<SelectOption>) => {
  const { inputValue } = props?.selectProps || '';

  return (
    <>
      <ReactSelectComponents.Option {...props}>
        <HighlightedText
          classNameHighlighted="font-bold text-indigo-bright-600"
          input={props?.data?.label}
          term={inputValue}
        />
      </ReactSelectComponents.Option>
      <div className="bg-cool-gray-200 h-[1px] w-full my-1 last:hidden" />
    </>
  );
};

const SmallOption = (props: OptionProps<SelectOption>) => {
  const { inputValue } = props?.selectProps || '';

  return (
    <ReactSelectComponents.Option className="flex flex-1" {...props}>
      <div className="flex flex-row">
        <div className="flex grow font-bold">
          <HighlightedText
            classNameHighlighted="font-bold"
            input={props?.data?.label}
            term={inputValue}
          />
        </div>
        {props.isSelected && (
          <div className="flex items-center px-2">
            <IconFlatCheck className="[&_*]:stroke-black [&_*]:stroke-1" />
          </div>
        )}
      </div>
      {props.data.sublabel && (
        <div className="text-cool-gray-500">{props.data.sublabel}</div>
      )}
    </ReactSelectComponents.Option>
  );
};

const Input = (props: InputProps) => {
  return (
    <ReactSelectComponents.Input
      {...props}
      data-enable-shortcuts={[
        'meta+enter',
        'meta+k',
        !props.selectProps?.menuIsOpen ? 'enter' : null,
      ]
        .filter(x => !!x)
        .join(',')}
    />
  );
};

const EditableInput = (props: InputProps) => (
  <Input {...props} isHidden={false} />
);
