import { useEffect, useState } from 'react';

import {
  StepComponentFC,
  StepComponentSharedProps,
} from '@assured/step-renderer';
import RedirectComponentSpec from '@assured/step-renderer/types/step-components/RedirectComponent';
import Title from '~/src/components/ClaimWorkflow/Title';

// named this way to avoid confusion with React Router's <Redirect />
interface RedirectComponentProps
  extends StepComponentSharedProps<RedirectComponentSpec, string> {}

const RedirectComponent: StepComponentFC<RedirectComponentProps> = ({
  step_component,
  updateValue,
  attemptSubmit,
}) => {
  // this function must be defined inside the component so it is recalculated on rerender
  // because we clear this value when we proceed in the flow
  const hasHashParams = () => !!window.__assuredInitialHash?.length;

  const { destinationUrl } = step_component;

  const [hasUpdatedValue, setHasUpdatedValue] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  useEffect(() => {
    let handle: NodeJS.Timeout;

    if (hasHashParams()) {
      // don't redirect if we're returning from the redirect

      if (hasUpdatedValue && !hasAttemptedSubmit && attemptSubmit) {
        attemptSubmit();
        setHasAttemptedSubmit(true);
        return;
      }

      handle = setTimeout(() => {
        setHasUpdatedValue(true);
        // we send back the whole hash for processing on the back end
        updateValue(step_component.field, window.__assuredInitialHash);
      }, 5000);
    } else {
      handle = setTimeout(() => {
        // we set a timeout so the user has some indication that they're about to be redirected

        window.__assuredInitialHash = '';
        window.location.href = destinationUrl;
        // Don't do window.location.replace(destinationUrl), because
        // it'll skip too far back due to how we handle locations in the workflow
      }, 5000);
    }

    return () => {
      clearTimeout(handle);
    };
  }, [updateValue, attemptSubmit, hasUpdatedValue, step_component.field, destinationUrl, hasAttemptedSubmit]);

  if (!hasHashParams()) {
    return (
      <>
        <Title
          titleClassName="ClaimWorkflowTitle"
          title={step_component.title_before_redirect}
        />
        <p>{step_component.body_before_redirect}</p>
      </>
    );
  } 
    return (
      <>
        <Title
          titleClassName="ClaimWorkflowTitle"
          title={step_component.title_after_redirect}
        />
        <p>{step_component.body_after_redirect}</p>
      </>
    );
  
};

RedirectComponent.stepConfig = {
  controlsTitle: true,
};

export default RedirectComponent;
