import { gql, useQuery } from '@apollo/client';

export const GET_WORKFLOW_ID = gql`
  query GetWorkflowId {
    currentUserWorkflowId
  }
`;

interface GetWorkflowIdData {
  currentUserWorkflowId: string;
}

export const useWorkflowId = () => {
  const { data, error, loading } = useQuery<GetWorkflowIdData>(
    GET_WORKFLOW_ID,
    {
      variables: {},
    },
  );

  return { data: data?.currentUserWorkflowId, error, loading };
};
