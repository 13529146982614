import moment from 'moment-timezone';
import { useCallback, useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';
import { noticeError } from '@assured/telemetry/src/common';

import { geolocationPermissionStatus } from './useGeolocation';

// So we can inspect the state of the initial search query to extract UTM parameters. Necessary in case
// useWorkflowTelemtry mounts later on, after the initial search query has been manipulated.
declare global {
  interface Window {
    __assuredInitialSearch?: string;
    __assuredInitialHash?: string;
  }
}

const TELEMETRY_INTERVAL_MS = 1000 * 5;

const getAdditionalData = () => {
  let utmData: {
    utmSource: string | null;
    utmMedium: string | null;
    utmCampaign: string | null;
    utmTerm: string | null;
    utmContent: string | null;
  } = {
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
    utmTerm: null,
    utmContent: null,
  };

  try {
    const params = new URLSearchParams(window.__assuredInitialSearch);
    utmData = {
      utmSource: params.get('utm_source'),
      utmMedium: params.get('utm_medium'),
      utmCampaign: params.get('utm_campaign'),
      utmTerm: params.get('utm_term'),
      utmContent: params.get('utm_content'),
    };
  } catch (e: any) {
    // unsupported
  }

  let timezone: string | null = null;
  try {
    timezone = moment.tz.guess();
  } catch (e: any) {
    // unsupported
  }

  return { ...utmData, timezone };
};

interface SaveWorkflowTelemetryVars {
  workflow: string;
  payload: {
    serializedLocation?: string;
  } & ReturnType<typeof getAdditionalData>;
}

interface WorkflowTelemetryProps {
  workflowId: string;
}

const SAVE_WORKFLOW_TELEMETRY = gql`
  mutation SaveWorkflowTelemetry(
    $workflow: ID!
    $payload: WorkflowTelemetryPayload!
  ) {
    saveWorkflowTelemetry(workflow: $workflow, payload: $payload)
  }
`;

export default function useWorkflowTelemetry({
  workflowId,
}: WorkflowTelemetryProps) {
  const [saveWorkflowTelemetry] = useMutation<{}, SaveWorkflowTelemetryVars>(
    SAVE_WORKFLOW_TELEMETRY,
  );

  const saveTelemetry = useCallback(
    ({
      serializedLocation,
    }: {
      serializedLocation?: string;
    } = {}) => {
      if (!workflowId) {
        return;
      }

      const payload = {
        serializedLocation,
        ...getAdditionalData(),
      };
      console.log(
        `[Assured Workflow Telemetry] Saving telemetry for workflow ${workflowId}: ${JSON.stringify(
          payload,
        )}`,
      );
      saveWorkflowTelemetry({
        variables: {
          workflow: workflowId,
          payload,
        },
      });
    },
    [workflowId, saveWorkflowTelemetry],
  );

  const onEvent: PositionCallback = useCallback(
    event => {
      const location = {
        accuracy: event.coords.accuracy,
        altitude: event.coords.altitude,
        altitudeAccuracy: event.coords.altitudeAccuracy,
        heading: event.coords.heading,
        latitude: event.coords.latitude,
        longitude: event.coords.longitude,
        speed: event.coords.speed,
        timestamp: event.timestamp,
      };
      saveTelemetry({ serializedLocation: JSON.stringify(location) });
    },
    [saveTelemetry],
  );

  const onEventError: PositionErrorCallback = useCallback(error => {
    noticeError(error);
    // eslint-disable-next-line no-console
    console.error(error);
  }, []);

  useEffect(() => {
    let pollId: number;

    async function _poll() {
      if (geolocationPermissionStatus.granted) {
        window.clearInterval(pollId);
        navigator.geolocation.getCurrentPosition(onEvent, onEventError);
      }
    }
    _poll();
    saveTelemetry();

    pollId = window.setInterval(() => _poll(), TELEMETRY_INTERVAL_MS);

    return () => {
      window.clearInterval(pollId);
    };
  }, [workflowId, saveTelemetry, onEvent, onEventError]);
}
